import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import Frontier from "../components/ClientPages/Frontier";

export default class FrontierPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Frontier" />
        <Frontier />
      </Fragment>
    );
  }
}
