import React, { PureComponent } from "react";

import styles from "./Main.module.scss";

import ClientBase from "./ClientBase";

import Video from "../../img/client_pages/frontier/video.mp4";
import Feature from "../../img/client_pages/frontier/feature.jpg";
import Design from "../../img/client_pages/frontier/design.jpg";
import Iphone from "../../img/client_pages/frontier/iphone.png";

export default class Frontier extends PureComponent {
  render() {
    return (
      <ClientBase
        mainImgStyle={styles.FrontierMainImg}
        video={Video}
        title="Frontier"
        theBrand={
          "Since 1994, Frontier Investment Management Company has provided meaningful results to individuals, trusts, and organizations through investment and planning solutions. As a long-standing partner of both Charles Schwab and TD Ameritrade, Frontier manages over $2.5B in assets."
        }
        ourWork={[
          "Brand Identity",
          "Web Design",
          "Social Style",
          "Social Strategy"
        ]}
        brandDesign={
          "Due to the serious nature of their work, we knew the Frontier brand needed to connect with both the practical and the emotional. Our goal was to convey trustworthiness, intelligence, familiarity, transparency, and approachability. We chose to use warm, muted colors and emotive imagery as we expanded upon Frontier’s mission and vision."
        }
        featureImg={Feature}
        webDesignText={
          "Through clean, minimal design, we made it easy for clients to navigate the site and discover the essence of Frontier’s purpose: to educate and advocate for their clients on all financial matters. Focusing on the essential, we incorporated Frontier’s already warm, personal atmosphere into the site’s visual identity."
        }
        webDesignImg={Design}
        socialDesignTitle="The Frontier Woman."
        socialDesignText={
          "The Frontier Woman aims to provide women with the education and tools to make major financial decisions. To reach Frontier’s female-identified audience, we created a new visual identity to convey confidence, independence, trust, family, and legacy."
        }
        socialDesignImg={Iphone}
      />
    );
  }
}
